.Artists{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Artists img{
max-width: 90%;
}

.Artists p{
    font-family: 'Paytone One', sans-serif;
    color: white;
   font-size: 1.5rem;
   line-height: 2rem;
    text-align: centre;
}

