.srutilaya-logo{
   /* 'marginTop':"8rem",
            'paddingTop':'0',
            'display':'block',
            'marginLeft':'auto',
            'marginRight':'auto',
            'border':'2px solid black',*/
    margin-top: 20rem;
    padding-top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    text-align: center;
}

.Hero{
    width: 100%;
    
}

@media screen and (max-device-width: 900px){
    
      /*.srutilaya-logo{
        margin-top: 20rem;
      }
      .sursangam-logo{
        margin-top: 100rem;
      }
      .about-us{
       margin-top: 800rem;
       
      }*/
}