body {
  margin: 0;
  padding: 0;
  background-image: url('./Assets/Images/Background.png');
  background-attachment: scroll;
  background-clip: border-box;
  background-position: center;
  background-position-y: 0rem;
 
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

