.AnotherAbout{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
}

.AnotherAbout p{
    font-family: 'Paytone One', sans-serif;
    color: #F2CD56;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    margin-bottom: none;
    padding-bottom: none;
}

.AnotherAbout img{
    max-width: 100%;
}

.coordi-names{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
}

.AnotherAbout h1, h2{
    font-family: 'Paytone One', sans-serif;
    color: #F2CD56;
    font-size: 3rem;
    text-align: center;
    margin-top: 5rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem
}

@media screen and (max-device-width:1200px) {
    .coordi-names{
        flex-direction: column;
        align-items: center;
    }
    .AnotherAbout p{
        font-size: 1rem;
    }
 }
