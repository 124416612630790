.Aboutus{
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.Aboutus img{
margin-right: 4rem;
border: 15px solid #900C3F ;
border-radius: 2rem;
line-height: 1rem;
}

.Aboutus p{
    font-family: 'Paytone One', sans-serif;
    color: #F2CD56;
    margin-left: 3rem;
    font-size: 1.5rem;
    text-align: left;
}

@media screen and (max-device-width: 1200px) {
   .Aboutus{
    margin-top: 1rem;

    flex-direction: column;
    align-items: center;
   }

   .Aboutus img{
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
.Aboutus p{
align-self: center;
margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
}
}